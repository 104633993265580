import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import TypeToBlock from "../components/type-to-block";
import Modal from "../components/modal/Modal";
import { AuthFormStrings } from "../components/strings/AuthStrings";
import MikesPurchaseComplete from "../components/landing/MikesPurchaseComplete";
import { ProductModal } from "../lib/components/ProductModal";
import LandingPurchaseComplete from "../components/landing/LandingPurchaseComplete";
import SubscriptionPurchaseContainer from "../components/PurchaseContainer/SubscriptionPurchaseContainer";

export const query = graphql`
  query ($languageISO: String!, $language: String!, $slug: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
    page: contentfulLandingPages(
      slug: { eq: $slug }
      node_locale: { eq: $languageISO }
    ) {
      slug
      id
      metaDescription
      metaTitle
      stripeProductId
      stripeProductName
      stripeProductDescription
      stripeProductAmount
      stripeProductType
      stripeBillingHeading
      stripeBillingSubHeading
      cta1
      cta1Link
      cta2
      cta2Link
      purchaseSuccessfulMessage {
        purchaseSuccessfulMessage
      }
      descriptionMessage {
        descriptionMessage
      }
      messageImage {
        file {
          url
        }
      }
      modules {
        id
        heading
        name
        subheading
        type
        flipBlocks
        items {
          ... on ContentfulPlanModule {
            id
              planSlug
              planName
              shortDescription
              featureImage {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
              featureImageMobile {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
          }
          ... on ContentfulItems {
            content {
              raw
            }
            body
            heading
            id
            link
            linkHelpText
            linkText
            subheading
            desktopImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            mobileImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            video
          }
        }
      }
    }
  }
`;

const Page = ({
  data: {
    page: {
      metaTitle,
      metaDescription,
      modules,
      stripeProductId,
      stripeProductName,
      stripeProductDescription,
      stripeProductAmount,
      stripeProductType,
      stripeBillingHeading,
      stripeBillingSubHeading,
      cta1,
      cta1Link,
      cta2,
      cta2Link,
      purchaseSuccessfulMessage: { purchaseSuccessfulMessage },
      descriptionMessage: { descriptionMessage },
      messageImage: {
        file: { url },
      },
    },
    ribbon,
    header,
    footer,
  },
}) => {
  const [show, setShow] = React.useState(false);
  let hasBanner;
  if (modules[0].type === "Full page horizontal carousel") {
    hasBanner = "white-banner";
  } else {
    hasBanner = "no-banner";
  }

  useEffect(() => {
    if(document.querySelector('.white-banner')){
      document.querySelector('.white-banner').classList.add('no-border');
    }
  },[])

  return (
    <>
      <Layout
        header={header}
        ribbon={ribbon}
        footer={footer}
        hasBanner={hasBanner}
      >
        <Seo title={metaTitle} metaDescription={metaDescription} />
        {(modules && modules.length) > 0 &&
          modules.map(
            (module, key) =>
              module.type !== "Plan List Block" && (
                <TypeToBlock
                  key={`module-${key}`}
                  stripePlanMonthly={{
                    code: stripeProductId,
                    name: stripeProductName,
                    description: stripeProductDescription,
                    amount: parseInt(stripeProductAmount),
                    currency: "USD",
                  }}
                  setShow={setShow}
                  module={module}
                />
              )
          )}
        {stripeProductType === "Product" && (
          <Modal open={show}>
            <AuthFormStrings>
              <ProductModal
                product={{
                  code: stripeProductId,
                  name: stripeProductName,
                  description: stripeProductDescription,
                  amount: parseInt(stripeProductAmount),
                }}
                language="en"
              >
                <LandingPurchaseComplete
                  cta1={cta1}
                  cta1Link={cta1Link}
                  cta2={cta2}
                  cta2Link={cta2Link}
                  purchaseSuccessfulMessage={purchaseSuccessfulMessage}
                  descriptionMessage={descriptionMessage}
                  messageImage={url}
                />
              </ProductModal>
            </AuthFormStrings>
          </Modal>
        )}
        {stripeProductType === "Subscription" && (
          <Modal open={show}>
            <SubscriptionPurchaseContainer
              stripeProductId={stripeProductId}
              stripeProductName={stripeProductName}
              stripeBillingHeading={stripeBillingHeading}
              stripeBillingSubHeading={stripeBillingSubHeading}
              stripeProductDescription={stripeProductDescription}
              stripeProductAmount={stripeProductAmount}
            />
          </Modal>
        )}
      </Layout>
    </>
  );
};

export default Page;
