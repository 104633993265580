import { useAxios } from "./useAxios";
import * as config from "../config";
import React from "react";
import { getSymbolFromCurrency } from "../global/currency";

export interface IStripeSubscription {
  lastResponse?: any;
  rawJsonObject?: any;
  active: boolean;
  aggregateUsage?: any;
  amount: number;
  amountDecimal: number;
  billingScheme: string;
  created: number;
  currency: string;
  deleted?: any;
  id: string;
  interval: "month" | "year";
  intervalCount: number;
  livemode: boolean;
  metadata: { [key: string]: any };
  nickname: string;
  object: string;
  product: string;
  tiers?: any;
  tiersMode?: any;
  transformUsage?: any;
  trialPeriodDays: number;
  usageType: string;
  productObject?: any;
}

export interface IStripeSubscriptionAdapter extends IStripeSubscription {
  name: string;
  interval_count: number;
  planId: string;
}

type UseStripeProduct<T = IStripeSubscription> = () => IStripeSubscription[];

export function useStripeProduct<T = IStripeSubscription[]>(
  productId: string,
  _default: IStripeSubscription[],
  fn?: (subs: IStripeSubscription[]) => T
): T {
  const defaultSubs: any = React.useMemo(
    () => (fn ? fn(_default) : _default),
    [fn, _default]
  );
  if (typeof window === "undefined") return defaultSubs;
  const [{ data }] = useAxios({
    url: `${config.urls.getStripePlans}?productId=${productId}`,
    method: "GET",
  });
  return React.useMemo(() => {
    if (data?.result?.length > 0) {
      const subs = data.result.filter((sub: IStripeSubscription) => sub.active);
      return fn ? fn(subs) : subs;
    }
    return defaultSubs;
  }, [data, defaultSubs]);
}

export const adaptStripeSubscription = (
  sub?: IStripeSubscription | null
): IStripeSubscriptionAdapter | undefined => {
  return sub
    ? {
        ...sub,
        planId: sub.id,
        interval_count: sub.intervalCount,
        name: sub.nickname,
      }
    : (sub as any);
};

export const getSubscriptionText = (
  subscription: IStripeSubscription,
  language: string = "en"
) => {
  const intervalShort = language === "es" ? "mes" : "mo";
  const intervalLong = language === "es" ? `mes` : `month`;
  let title = language === "es" ? "MENSUAL" : "MONTHLY";
  let textPeriod = language === "es" ? "al mes" : "every month";
  let currencySymbol = getSymbolFromCurrency(
    subscription.currency.toUpperCase()
  );
  let amountMonthly = subscription.amount;
  if (subscription.interval === "month" && subscription.intervalCount === 3) {
    title = language === "es" ? "TRIMESTRAL" : "QUARTERLY";
    textPeriod = language === "es" ? "cada tres meses" : "every 3 months";
    amountMonthly = Math.floor(amountMonthly / 3);
  } else if (subscription.interval === "year") {
    title = language === "es" ? "ANUAL" : "YEARLY";
    textPeriod = language === "es" ? "facturados hoy" : "once a year";
    amountMonthly = Math.floor(amountMonthly / 12);
  }
  const amount = subscription.amount / 100;
  const amountText = `${currencySymbol}${amount}${
    language === "es" &&
    currencySymbol === "$" &&
    subscription.currency.toLowerCase() === "usd"
      ? " USD"
      : ""
  }`;
  const footer =
    language === "es"
      ? `Paga ${amountText} ${textPeriod}`
      : `${amountText} billed ${textPeriod}`;

  const dollars = Math.floor(subscription.amount / 100);
  const cents = subscription.amount % 100;
  const dollarsMonthly = Math.floor(amountMonthly / 100);
  const centsMonthly = amountMonthly % 100;

  const currencyUpper = subscription.currency.toUpperCase();
  const amountSuffix =
    ["USD", "AUD", "CAD", "MXN"].indexOf(currencyUpper) > -1
      ? currencyUpper
      : "";

  return {
    intervalShort,
    title,
    textPeriod,
    currencySymbol,
    amount,
    amountMonthly,
    amountText,
    footer,
    dollars,
    cents,
    dollarsMonthly,
    centsMonthly,
    amountSuffix,
    currencyUpper,
    intervalLong,
  };
};
