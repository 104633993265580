import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { AuthFormStrings } from "../strings/AuthStrings";
import Modal from "../modal/Modal";
import { SubscriptionModal } from "../subscriptionNewVersion";
import {
  useStripeProduct,
  adaptStripeSubscription,
  getSubscriptionText,
} from "../../hooks/useStripeProduct";

const breakpoint = "600px";

const SubscriptionPurchaseContainer = ({
  stripeProductId,
  stripeProductName,
  stripeProductDescription,
  stripeProductAmount,
  stripeBillingHeading,
  stripeBillingSubHeading
}) => {
  const { t } = useTranslation();
  const data = {
    homeSubscriptionBlock: {
      primaryButton: t("startFree"),
      secondaryButton: t("continue"),
    },
    startPage: {
      success: t("accountActivated"),
      button: t("downloadFitplan"),
      exit: t("alreadyHaveTheApp"),
    },
    subscribePage: {
      title: t("yourGoalsAreWaiting"),
      subtitle: t("tryFitplanFree"),
    },
  };

  const [allSubscriptionsState, setAllSubscriptionsState] = useState([]);
  const allSubscriptions = useStripeProduct(stripeProductId)?.map((sub) => adaptStripeSubscription(sub))
  // let allSubscriptions3;
  // if(allSubscriptions2) {
  //   allSubscriptions3 = allSubscriptions2.map((sub) => adaptStripeSubscription(sub))
  // }
  // console.log("allSubscriptions3",allSubscriptions3)

  // const allSubscriptions = useStripeProduct(
  //   stripeProductId,
  //   [
  //     {
  //       lastResponse: null,
  //       rawJsonObject: null,
  //       active: true,
  //       aggregateUsage: null,
  //       amount: stripeProductAmount,
  //       amountDecimal: stripeProductAmount,
  //       billingScheme: "per_unit",
  //       created: 1611785524,
  //       currency: "usd",
  //       deleted: null,
  //       id: stripeProductId,
  //       interval: "month",
  //       intervalCount: 1,
  //       livemode: false,
  //       metadata: {},
  //       nickname: stripeProductName,
  //       object: "plan",
  //       product: stripeProductId,
  //       tiers: null,
  //       tiersMode: null,
  //       transformUsage: null,
  //       trialPeriodDays: null,
  //       usageType: "licensed",
  //       productObject: null,
  //     },
  //   ],
  //   (subs) =>
  //     subs
  //       // .filter((sub) => sub.interval === "month")
  //       .map((sub) => adaptStripeSubscription(sub))
  // );

  if (allSubscriptions) {
    const selectedPlan = allSubscriptions[0];
    selectedPlan.nickname = stripeProductName;
    selectedPlan.stripeBillingHeading = stripeBillingHeading;
    selectedPlan.stripeBillingSubHeading = stripeBillingSubHeading;
    return (<SubscriptionModal
      allowCoupon={true}
      selectedPlan={selectedPlan}
      availablePlans={allSubscriptions}
      selectedInvoice={null}
      key={`stripe-modal-lp`}
      partner={"campaign-email"}
    />
    );
  } else {
    return <>Loading...</>
  }
};

export default SubscriptionPurchaseContainer;
