import * as React from "react";
import styled from "@emotion/styled";
import { Button } from "./Button";
import Checkmark from "../icons/Checkmark";

const LandingPurchaseComplete = ({ purchaseSuccessfulMessage, descriptionMessage, messageImage, cta1, cta1Link, cta2, cta2Link }) => {
    return (
        <PurchaseComplete>
            <PurchaseSuccessBox>
                <IconImage backgroundImage={messageImage} />
                <PurchaseTextArea>
                    <Checkmark size="10px" />
                    {purchaseSuccessfulMessage && <PurchaseText>{purchaseSuccessfulMessage}</PurchaseText>}
                </PurchaseTextArea>
            </PurchaseSuccessBox>
            <PurchaseInfoBox>
                {descriptionMessage && <TextBox>{descriptionMessage}</TextBox>}
                {(cta1 && cta1Link) && <OpenButton to={cta1Link}>{cta1}</OpenButton>}
                {(cta2 && cta2Link) && <OpenButton to={cta2Link}>{cta2}</OpenButton>}
            </PurchaseInfoBox>
        </PurchaseComplete>
    );
};

const BarlowStyle = styled.div`
  font-family: "Barlow";
`;

const PurchaseComplete = styled.div`
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.1);
  width: 82vw;
  max-width: 402px;
  padding: 16px;
  margin: 10px auto;
`;

const PurchaseSuccessBox = styled.div`
  border-radius: 5px;
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  background-color: #1d222b;

  padding: 16px;
  display: flex;
  position: relative;
`;

const IconImage = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  border-radius: 100%;

  width: 80px;
  height: 80px;
  position: absolute;
  top: -12px;
  left: -12px;
  overflow: hidden;
`;


const PurchaseTextArea = styled(BarlowStyle)`
  color: #fff;
  font-size: 17px;
  line-height: 1.41;

  margin: 0 auto;
  padding-left: 50px;
  display: flex;
`;

const PurchaseText = styled.div`
  padding: 0 24px 0 8px;
`;

const PurchaseInfoBox = styled(BarlowStyle)`
  font-size: 17px;
  text-align: center;
  line-height: 1.41;

  padding: 48px 0 0;
`;

const OpenButton = styled(Button)`
  max-width: 300px;
  margin: 10px auto !important;

  :hover,
  :focus {
    background-image: linear-gradient(257deg, #00bd98, #28d95a);
  }
`;

const TextBox = styled.p`
  color: #000;
  font-size: 17px;
  line-height: 1.41;
  text-align: center;

  margin: 20px 0 0;
  padding: 20px 30px;
`;

export default LandingPurchaseComplete;
